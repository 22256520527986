<template>
  <div class="content account-journal-init-list">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('科目余额初始化') }}
      </a-button>
    </section>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <SelectAccountSet
            :placeholder="$t(`账簿`)"
            :code="formData.accountSetUnionCode"
            @change="changeAccountSet"
          />
        </a-col>
        <a-col :span="6">
          <a-input :disabled="true" :value="formData.businessUnitName" />
        </a-col>
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.orderSn"
            :placeholder="$t('请输入单据编号')"
          />
        </a-col>
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div class="table-content">
      <div class="table-header">
        <OperateBtn
          api="finishAccountJournalInit"
          :text="$t('结束初始化')"
          :params="{
            idList: selectedRowKeys,
          }"
          :disabled="selectedRows.length === 0"
          @success="searchHandle"
        />
      </div>
      <div class="table-body">
        <a-table
          size="small"
          bordered
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :columns="columns"
          :loading="loading"
          :dataSource="tableData"
          :pagination="false"
          rowKey="id"
          :scroll="{ x: 1500, y: scrollHeight }"
        >
          <template slot="orderSn" slot-scope="text, record">
            <a @click="viewDetail(record)">{{ text }}</a>
          </template>
        </a-table>
      </div>
      <div class="table-footer mt10">
        <pagination
          :pageNo="pageNo"
          :length="pageSize"
          :total-count="total"
          @paginate="paginate"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import SelectAccountSet from '@/component/selectAccountSet.vue'
import pagination from '@/component/pagination.vue'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'AccountJournalInitList',
  components: { SelectAccountSet, pagination },
  data() {
    return {
      docType: 'AccountJournalInit',
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      loading: false,
      total: 0,
      pageNo: 1,
      pageSize: 20,

      columns: [],
    }
  },
  mounted() {
    this.setColumns()
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    scrollHeight() {
      return window.innerHeight - 300
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.setColumns()
    },
    setColumns() {
      this.columns = [
        {
          title: this.$t('单据编号'),
          dataIndex: 'orderSn',
          scopedSlots: { customRender: 'orderSn' },
        },
        {
          title: this.$t('账簿'),
          dataIndex: 'accountSetName',
        },
        {
          title: this.$t('结算组织'),
          dataIndex: 'businessUnitName',
        },
        {
          title: this.$t('初始化期间'),
          dataIndex: 'accountingPeriod',
          customRender: (text) => text.replace('-', this.$t('年')) + this.$t('期'),
        },
        {
          title: this.$t('状态'),
          dataIndex: 'status',
          customRender: (text) => this.$store.getters.initAccountBalanceStatusMapping[text],
        },
        {
          title: this.$t('创建时间'),
          dataIndex: 'createdAt',
        },
        {
          title: this.$t('创建人'),
          dataIndex: 'createdUser',
        },
      ]
    },
    searchHandle(pageNo) {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.queryList(pageNo)
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
      this.searchHandle(1)
    },
    paginate(data) {
      this.pageNo = data.pageNo
      this.pageSize = data.pageSize
      this.queryList()
    },
    createHandle() {
      this.$router.push({ name: 'accountJournalInitDetail' })
    },
    async queryList(pageNo = 0) {
      if (pageNo) {
        this.pageNo = pageNo
      }
      this.loading = true
      await http({
        url: api.getAccountJournalInitList,
        data: {
          page_no: this.pageNo,
          page_size: this.pageSize,
          ...this.formData,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'accountJournalInitDetail/' + record.orderSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.orderSn,
        },
        route: `/accountJournal/accountJournalInitDetail?id=${record.id}&sn=${record.orderSn}`,
      })
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
  },
}
</script>
